.backdrop {
  color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 20px !important;
}

.inactivityModalRoot {
  display: flex !important;
  align-items: center !important;
  max-width: 322px !important;
  border-radius: 10px !important;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(62, 64, 70, 0.08);
}

@media screen and (max-width: 400) {
  .inactivityModalRoot {
    max-height: unset !important;
  }
  .rootSessionTimeoutModal {
    max-height: unset !important;
  }
}

.closeOverlay {
  top: 29px !important;
  color: #ffffff !important;
  width: 44px;
  cursor: pointer !important;
  height: 28px;
  position: relative !important;
}

.rootSessionTimeoutModal {
  display: flex !important;
  align-items: center !important;
  max-width: 332px !important;
  border-radius: 10px !important;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(62, 64, 70, 0.08);
}

.card_content {
  text-align: center !important;
  padding: 0px !important;
  margin: 0px !important;
}

.continueAppButton {
  background: #661c69 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  letter-spacing: 0.1px !important;
  font: normal 700 14px/16px Arial, sans-serif !important;
  width: 103px !important;
  height: 32px !important;
  margin: 17px 0 30px 0 !important;
  border-radius: 56px !important;
  opacity: 1 !important;
  text-transform: none !important;
  min-width: unset !important;
}

.continueAppButton:hover {
  background-color: #661c69 !important;
}
.continueAppButton:focus {
  outline: 2px solid #661c69;
  outline-offset: 2px;
}

.restart_app_button {
  background: #661c69 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
  letter-spacing: 0.1px !important;
  font: normal normal normal 14px/17px Arial, sans-serif !important;
  width: 200px !important;
  height: 44px !important;
  margin: 17px 0px 30px 0px !important;
  border-radius: 56px !important;
  opacity: 1 !important;
  text-transform: none !important;
  min-width: unset !important;
}

.restart_app_button:focus{
  outline: 2px solid #9c8e9c;
  outline-offset: 2px;
}

.restart_app_button:hover {
  background-color: #661c69 !important;
}

.textOneInactiveStyle {
  font: normal 400 16px/18px Arial, sans-serif !important;
  color: #000000 !important;
  letter-spacing: 0.1px !important;
  margin-top: 42px !important;
  margin-left: 26px !important;
  margin-right: 26px !important;}

.textOneSessionStyle {
  font: normal 700 16px/19px Arial, sans-serif !important;
  color: #000000 !important;
  letter-spacing: 0.12px !important;
  margin-top: 42px !important;
  margin-left: 26px !important;
  margin-right: 26px !important;
}

.textTwoSessionStyle {
  font: normal 400 16px/18px Arial, sans-serif !important;
  color: #000000 !important;
  letter-spacing: 0.1px !important;
  margin-top: 20px !important;
  margin-left: 26px !important;
  margin-right: 26px !important;
}

.close_icon {
  width: 34px !important;
  height: 34px !important;
  cursor: pointer !important;
  margin: 5px 5px 0 0 !important;
}

.modal_close {
  color: #661c69 !important;
  text-align: right !important;
  height: 35px !important;
}
.sessionmodal {
  outline: 'none';
}
.sessioncontainer {
  display: flex;
  justify-content: flex-end;
}
.closeicon {
  width: '25px';
  height: '25px';
}
